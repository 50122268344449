import baseLayout from "@/layout/BaseLayout";

export const defaultRouterMap = [
    {
        path: '/',
        name: 'index',
        component: baseLayout,
        redirect: '/home',
        children: [
            {
                name:'home',
                path:'/home',
                component:()=>import('@/pages/home'),
                meta:{show:true, needLogin:false}
            },
            {
                name: 'detail',
                path: '/detail/:id',
                component:()=>import('@/pages/detail'),
                meta: {show:true,needLogin: false}
            }
        ]
    }
    ,
    // {
    //     name:'search',
    //     path:'/search/:keyword?', // params参数需要占位   问号代表该参数可传可不传，没有问号一定要传
    //     component:()=>import('@/pages/Search'),
    //     meta:{show:true, needLogin:false},
    //     //路由组件能不能传递props数据？  可以
    //     // 布尔值写法:params
    //     // prop:true,
    //     // 对象写法：额外给路由传递一些props
    //     // props:{a:1,b:2},
    //     // 函数写法（常用）：可以把 params参数，query参数，通过props传递给路由组件
    //     // props:($route) => {  // 把这个函数传给路由组件，然后路由组件收到参数后，路由组件写keyword就能代表$route.params.keyword了
    //     //     return {keyword:$route.params.keyword, k:$route.query.k}; //其实路由组件的props接收到的是return的数据
    //     // }
    //
    // }
    // ,
    // {
    //     name:'register',
    //     path:'/register',
    //     component:()=>import('@/pages/Register'),
    //     // meta在这里用于判断是否显示footer组件
    //     meta:{show:false, needLogin:false}
    // }
    // ,
    // {
    //     name:'login',
    //     path:'/login',
    //     component:()=>import('@/pages/Login'),
    //     meta:{show:false, needLogin:false}
    // }
    // ,
    // {
    //     name:'detail',
    //     path:'/detail/:skuid', // 展位符后面没有问号，说明该参数一定要传
    //     component:()=>import('@/pages/Detail'),
    //     meta:{show:true, needLogin:false}
    // },
    // {
    //     name:'addcartsuccess',
    //     path:'/addcartsuccess',
    //     component:()=>import('@/pages/AddCartSuccess'),
    //     meta:{show:true, needLogin:false}
    // },
    // {
    //     name:'shopcart',
    //     path:'/shopcart',
    //     component:()=>import('@/pages/ShopCart'),
    //     meta:{show:true, needLogin:true}
    // },
    // {
    //     name:'trade',
    //     path:'/trade',
    //     component:()=>import('@/pages/Trade'),
    //     meta:{show:true, needLogin:true},
    //     // 路由独享守卫
    //     beforeEnter:(to, from, next) => {
    //         if(from.path == '/shopcart' || from.path == '/'){  // 想去交易页面，必须是从购物车页面而来 (后面的from.path == '/'代表刷新，刷新是从 / 跳转到 /trade)
    //             next();
    //         }else{ // 其他路由组件而来，无法进入（停留在当前页）
    //             // next(false) 的作用是：url会重置到from路由对应的地址（在这里：如果在home页面输入trade，是无法跳转的，然后next(false)恢复url为home）
    //             next(false);
    //         }
    //     }
    // },
    // {
    //     name:'pay',
    //     path:'/pay',
    //     component:()=>import('@/pages/Pay'),
    //     meta:{show:true, needLogin:true},
    //     // 路由独享守卫
    //     beforeEnter:(to, from, next) => {
    //         if(from.path == '/trade' || from.path == '/'){ // (后面的from.path == '/'代表刷新，刷新是从 / 跳转到 /pay)
    //             next();
    //         }else{
    //             next(false);
    //         }
    //     }
    // },
    // {
    //     name:'paysuccess',
    //     path:'/paysuccess',
    //     component:()=>import('@/pages/PaySuccess'),
    //     meta:{show:true, needLogin:true},
    //     // 路由独享守卫
    //     beforeEnter:(to, from, next) => {  // (后面的from.path == '/'代表刷新，刷新是从 / 跳转到 /paysuccess)
    //         if(from.path == '/pay'){
    //             next();
    //         }else{
    //             next(false);
    //         }
    //     }
    // },
    // {
    //     name:'center',
    //     path:'/center',
    //     component:()=>import('@/pages/Center'),
    //     meta:{show:true, needLogin:true},
    //     // 二级路由组件
    //     children:[
    //         {
    //             path:'myorder',  // 二级路由的路径前面不要加斜杠 /
    //             component:()=>import('@/pages/Center/myOrder'),
    //             meta:{show:true, needLogin:true}
    //         },
    //         {
    //             path:'grouporder', // 二级路由的路径前面不要加斜杠 /
    //             component:()=>import('@/pages/Center/groupOrder'),
    //             meta:{show:true, needLogin:true}
    //         },
    //         // 重定向（一跳转到center，就默认显示myorder）
    //         {
    //             path:'/center',
    //             redirect:'/center/myorder'
    //         }
    //     ]
    // },
    // 重定向：在项目跑起来的时候，访问 / ，立马让他定向到首页
    {
        path:'/',
        redirect:'/home'
    }

]
