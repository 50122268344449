<template>
  <div id="app">
    <!-- 路由显示的地方 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  background-color: #f7f7fa;
}
</style>
