<template>
  <div>
  <Header></header>
  <router-view></router-view>
  <FixNva></FixNva>
  <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/layout/components/Header";
import Footer from "@/layout/components/Footer";
import FixNva from "@/layout/components/FixNva";
export default {
  name: "BaseLayout",
  components: {FixNva, Header,Footer}
}
</script>

<style scoped>
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
