// 配置路由的地方
import Vue from 'vue';
import VueRouter from 'vue-router';
// 使用插件
Vue.use(VueRouter);

// 引入路由组件
// import Home from '@/pages/Home'
// import Search from '@/pages/Search'
// import Register from '@/pages/Register'
// import Login from '@/pages/Login'
// import Detail from '@/pages/Detail'
// import AddCartSuccess from '@/pages/AddCartSuccess'
// import ShopCart from '@/pages/ShopCart'
// import Trade from '@/pages/Trade'
// import Pay from '@/pages/Pay'
// import PaySuccess from '@/pages/PaySuccess'
// import Center from '@/pages/Center'

// 引入二级路由信息
// import MyOrder from '@/pages/Center/myOrder'
// import GroupOrder from '@/pages/Center/groupOrder'

// 引入store
import store from '@/store'
import {defaultRouterMap} from "@/config/router.config";



// 先把VueRouter原型对象上的push方法保存（浅拷贝）
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
// 重写 push|replace 方法

VueRouter.prototype.push = function(location,resolve,reject){ // location：往哪里跳转
    if(resolve && reject){
        originPush.call(this,resolve,reject);
    }else{
        originPush.call(this,location,() => { },()=> { });
    }
}

VueRouter.prototype.replace = function(location,resolve,reject){ // location：往哪里跳转
    if(resolve && reject){
        originReplace.call(this,resolve,reject);
    }else{
        originReplace.call(this,location,() => { },()=> { });
    }
}




// 配置路由
let router =  new VueRouter({
    mode: 'history',
    // 配置路由：
    routes: defaultRouterMap,
    // 路由跳转后的滚动行为
    scrollBehavior(to, from, savedPosition) {
        // 返回的这个 y = 0，代表滚动条在最上方
        return { y: 0 }
    }
});

// 全局守卫：前置守卫（在路由跳转之前进行判断）
router.beforeEach(async (to, from, next) =>{
    // to：即将跳转到的（目标路由的信息）  from：将要离开的（从什么路由跳）  next：放行函数   next()--放行    next(path)--放行到指定路径
    // 用户登录了，才会有token
    let token = store.state.user.token;
    // 用户信息
    let name = store.state.user.info.name;

    if(token){ // 登陆了
        // 用户已经登录了，则不能让他去登录页面
        if(to.path == '/login'){ // 如果去登录页面，则跳转到首页
            next('/home');
        }else{  // 如果不是去登录页面
            // 注意：这里刷新页面，则会清空仓库中的用户信息，因为没有用户名会触发else，然后派发action获取用户信息
            if(name){ // 如果有用户名（比如已经登录的home首页跳转到search页，仓库中一直有用户信息，则header一直能显示）
                next();
            }else{  // 如果没有用户名，则派发action让仓库存储用户信息再跳转
                try {
                    await store.dispatch('user/getUserInfo');
                    // 获取用户信息成功，则放行
                    next();
                } catch (error) { // 这里的捕获错误：如果携带token发请求获取用户信息，而服务器识别token已经过期了（token有过期时间）
                    // token 虽然失效了 但是你本地还保留了那个失效的token  所以还是有的 只不过无法获取用户信息了
                    // 如果 token过期了：
                    // 1. 清除前台的 用户信息 和 token
                    // 2. 回到登录页面去重新登录
                    await store.dispatch('user/userLogout'); // 清除
                    next('/login'); // 跳转到登录页面

                }
            }
        }
    }
    else{ // 未登录: 不能去交易和支付相关的页面、也不能去个人中心
        // 未登录去上面这些页面，应该跳转到登录页面
        if(to.meta.needLogin == true){
            next('/login?redirect=' + to.path); // 把未登录的时候想去的页面存储在地址栏的query参数，当点击登录按钮后，跳转到想去的页面（可以结合登录页面的按钮回调看）
        }else{  // 如果去的不是这些页面，则放行
            next();
        }
    }
})


export default router;
