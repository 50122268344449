import Vue from 'vue'
import App from './App.vue'
import store from "@/store";
import router from "@/router";

new Vue({
  render: h => h(App),
  // 全局事件总线$bus配置
  // beforeCreate() {
  //   Vue.prototype.$bus = this;
  //
  //   Vue.prototype.$API = API;
  // },
  // 注册路由 可以K-V一致，省略V
  // 注册路由信息：当这里书写router的时候，组件身上都拥有$route,$router属性
  router:router,
  // 注册仓库:组件实例的身上会多出一个$store属性
  store:store,
}).$mount('#app')
