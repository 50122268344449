<template>
  <!--  <div class="header-chunk">-->

  <!--  </div>-->
  <div class="header-chunk">
    <div class="header-one">
      <div class="base-width header-content header-content-one">
        <div class="nav-left">
          <li class="nav-item"><span class="nav-item-name">首页</span></li>
          <li class="nav-item"><span class="nav-item-name">网站地图</span></li>
        </div>
        <div class="nav-right">
          <div style="display: flex; justify-content: flex-start; align-items: center; margin-right: 10px;">
            <li class="nav-item"><span class="nav-item-name">请登录</span></li>
            <li class="nav-item"><span class="nav-item-name">注册</span></li>
          </div>
          <li class="nav-item"><span class="nav-item-name">客服热线：15527015492</span></li>
        </div>
      </div>
    </div>
    <div class="header-two">
      <div class="base-width header-content header-content-two">
        <div class="logo"><img
            src="https://fuluapiosstest2018.oss-cn-hangzhou.aliyuncs.com/banner/cardsjihaoancnleftlogo.png"
            style="width: 259px; height: 50px;"></div>
        <div class="search-content">
          <div class="search-box">
            <div class="search-container">
              <div class="search-input"><span class="ant-input-affix-wrapper ant-search-input"><span
                  class="ant-input-prefix"><span role="img" aria-label="search"
                                                 class="anticon anticon-search search-icon"><svg viewBox="64 64 896 896"
                                                                                                 focusable="false"
                                                                                                 data-icon="search"
                                                                                                 width="1em"
                                                                                                 height="1em"
                                                                                                 fill="currentColor"
                                                                                                 aria-hidden="true"><path
                  d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span></span><input
                  class="ant-input" placeholder="请输入商品名称进行搜索" type="text" value=""><span
                  class="ant-input-suffix"><button type="button" class="ant-btn search-btn"><span>搜 索</span></button></span></span>
              </div>
            </div>
          </div>
          <div class="hot-search-product"><span class="hot-title">热门搜索：</span><span
              class="hot-menu-item">映客钻石</span><span class="hot-menu-item">鱼翅</span><span
              class="hot-menu-item">秀币</span><span class="hot-menu-item">网易LOOK直播</span><span
              class="hot-menu-item">支付宝立减金</span><span class="hot-menu-item">比心钻石</span><span
              class="hot-menu-item">京东E卡</span><span class="hot-menu-item">战火互娱一卡通</span><span
              class="hot-menu-item">ZZ币</span><span class="hot-menu-item">星币</span></div>
        </div>
<!--        <div class="right-logo">-->
<!--          <img class="sub-logo"-->
<!--                                     src="https://fuluapiosstest2018.oss-cn-hangzhou.aliyuncs.com/banner/cardsjihaoancnrightlogo.png">-->
<!--        </div>-->
      </div>
    </div>
<!--    <div class="fix-search" style="top: -70px;">-->
<!--      <div class="base-width fix-search-content"><img class="logo-small"-->
<!--                                                      src="https://fuluapiosstest2018.oss-cn-hangzhou.aliyuncs.com/banner/cardsjihaoancnleftlogo.png">-->
<!--        <div class="search-con">-->
<!--          <div class="search-container">-->
<!--            <div class="search-input"><span class="ant-input-affix-wrapper ant-search-input"><span-->
<!--                class="ant-input-prefix"><span role="img" aria-label="search"-->
<!--                                               class="anticon anticon-search search-icon"><svg viewBox="64 64 896 896"-->
<!--                                                                                               focusable="false"-->
<!--                                                                                               data-icon="search"-->
<!--                                                                                               width="1em" height="1em"-->
<!--                                                                                               fill="currentColor"-->
<!--                                                                                               aria-hidden="true"><path-->
<!--                d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span></span><input-->
<!--                class="ant-input" placeholder="请输入商品名称进行搜索" type="text" value=""><span-->
<!--                class="ant-input-suffix"><button type="button"-->
<!--                                                 class="ant-btn search-btn"><span>搜 索</span></button></span></span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.header-chunk {
  background: #fff;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 4%) !important;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 4%) !important;
}
.header-chunk .header-one {
  height: 40px;
  background: #333333;
}
.header-chunk .header-content-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.header-chunk .header-content {
  height: 100%;
}
.base-width {
  width: 1226px;
  margin: 0 auto;
}
.header-chunk .header-content-one .nav-left, .header-chunk .header-content-one .nav-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.header-chunk .header-content-one .nav-item:first-child {
  padding-left: 0;
}

.header-chunk .header-content-one .nav-item {
  height: 12px;
  padding: 0 8px;
  color: #b0b0b0;
  border-right: 1px solid #424242;
  line-height: 12px;
}
.header-chunk .header-content-one .nav-item .nav-item-name {
  cursor: pointer;
}
.header-chunk .header-two {
  height: 100px;
  position: relative;
  z-index: 10;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 4%);
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 4%);
}
.header-chunk .header-content-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-chunk .header-content {
  height: 100%;
}
.header-chunk .header-content-two .logo {
  width: 259px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-top: 6px;
}

.logo {
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.header-chunk .header-content-two .search-content {
  width: 640px;
  padding-left: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-chunk .header-content-two .right-logo {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0px;
}
.header-chunk .header-content-two .logo img {
  margin: 0;
  width: 259px;
  height: 50px;
}
.header-chunk .header-content-two .search-content .search-box {
  width: 640px;
  height: 40px;
  margin-top: 24px;
  border: none !important;
}
.header-chunk .header-content-two .search-content .hot-search-product {
  width: 600px;
  height: 14px;
  font-size: 12px;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi-Regular;
  font-weight: 400;
  text-align: left;
  color: #babfc9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 8px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-chunk .header-content-two .search-content .hot-search-product .hot-title {
  margin-right: 16px;
}
.header-chunk .header-content-two .search-content .hot-search-product .hot-menu-item {
  margin-right: 16px;
  cursor: pointer;
}
.header-chunk .header-content-two .search-content .hot-search-product .hot-menu-item:hover {
  color: #ff6a01;
}
.search-container {
  width: 100%;
  height: 100%;
}
.search-container .search-input {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ff7301;
}
.search-container .ant-input-affix-wrapper {
  border: 0;
}

.search-container .ant-search-input {
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  padding-left: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.search-container .ant-input-affix-wrapper .ant-input-prefix {
  left: 30px
  px
;
}

.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ant-input-prefix {
  margin-right: 4px;
}
.search-container .search-icon {
  font-size: 18px;
  color: #c0c4cc;
  margin-right: 20px;
  margin-top: 2px;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.search-container .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.search-container .ant-input-affix-wrapper .ant-input-suffix {
  right: 3px;
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.search-container .search-btn {
  width: 60px;
  height: 32px;
  background: #ff6a01;
  border-radius: 17px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
}
.search-container .ant-input-affix-wrapper .ant-input-suffix .search-btn {
  line-height: 32px;
  text-align: center;
  padding: 0;
  border: 0;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-input:focus, .ant-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.search-container .ant-search-input .ant-input {
  background-color: rgba(24, 144, 25, 0);
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
